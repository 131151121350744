import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import Collapsible from 'react-collapsible';
import shapes from '../../../config/shapes';
import ScheduleLinePicto from '../../elements/schedules/ScheduleLinePicto';
import { LINES_TYPES } from '../../../config/line';

const renderMobileRootAccordionHeader = () => (
  <div className="toggle-heading text-blue toggle-center justify-space-between collapsible-header">
    <div className="text-black">
      <span>{Translate('schedules.search.list')}</span>
    </div>
    <div className="collapse arrow">
      <svg width="15" height="15" className="f-blue">
        <use xlinkHref="#icon-arrow-down" />
      </svg>
    </div>
  </div>
);

const renderAccordionHeader = (lines, title) => (
  <div className="toggle-heading text-blue toggle-center justify-space-between collapsible-header">
    <div className="title-icon">
      <svg width={30} height={30} className="m-r-0-5">
        <use xlinkHref={lines[0].type === LINES_TYPES.TRAM ? '#icon-tram-circle'
          : lines[0].type === LINES_TYPES.BUS_NIGHT ? '#icon-night-bus'
            : lines[0].type === LINES_TYPES.TRAIN_TER ? '#icon-sncf-circle'
              : lines[0].type === LINES_TYPES.AUTOCAR ? '#icon-autocar-circle'
                : lines[0].type === LINES_TYPES.SCODI ? '#icon-scodi'
                  : '#icon-batcub-circle'}
        />
      </svg>
      <span>{title}</span>
    </div>
    <div className="collapse">
      <div className="horizontal" />
      <div className="vertical" />
    </div>
  </div>
);

const renderAccordionHeaderBus = () => (
  <div className="toggle-heading text-blue toggle-center justify-space-between collapsible-header">
    <div className="title-icon">
      <svg width={30} height={30} className="m-r-0-5">
        <use xlinkHref="#icon-bus-circle" />
      </svg>
      <span>{Translate('line.types.bus')}</span>
    </div>
    <div className="collapse">
      <div className="horizontal" />
      <div className="vertical" />
    </div>
  </div>
);

const ListLinesAccordion = (props) => {
  const {
    trams, bus, busNight, scodi, batcub, trains, autocars, isMobile, tagging,
  } = props;
  const [priorityBus, setPriorityBus] = useState([]);
  const [classicBus, setClassicBus] = useState([]);
  const [specialBus, setSpecialBus] = useState([]);
  const isHomepage = useSelector(state => state.app.isHomepage);

  useEffect(() => {
    setPriorityBus(bus.filter(b => b.displayFirst).sort((a, b) => a?.code > b?.code));
    setClassicBus(bus.filter(b => !b.isSpecial && !b.displayFirst));
    setSpecialBus(bus.filter(b => b.isSpecial && !b.displayFirst));
  }, [bus]);

  const renderLineListMobile = (lines, title, borderBottom = true, displayFlex = false, mobileTabOpened = false) => (
    <div className={`
      ${displayFlex === true ? 'display-flex align-content-stretch align-center justify-space-between' : ''}
      ${borderBottom === true ? 'border-bottom' : ''}`}
    >
      <Collapsible
        trigger={renderAccordionHeader(lines, title)}
        open={mobileTabOpened}
        triggerTagName="div"
        tabIndex={0}
        transitionTime={300}
      >
        <div className="lines-wrapper wrap display-flex align-content-stretch align-center">
          {
            lines.map(line => <ScheduleLinePicto line={line} key={line.id} size={30} tagging={tagging} />)
          }
        </div>
      </Collapsible>
    </div>
  );

  const renderLineListDesktop = (lines, title, borderBottom = true) => (
    <>
      <div className={`${borderBottom === true ? 'border-bottom' : ''}`}>
        <Collapsible
          trigger={renderAccordionHeader(lines, title)}
          triggerTagName="div"
          tabIndex={0}
          transitionTime={300}
        >
          <div className="lines-wrapper wrap display-flex align-content-stretch align-center">
            {
              lines.map(line => <ScheduleLinePicto line={line} key={line.id} size={30} tagging={tagging} />)
            }
          </div>
        </Collapsible>
      </div>
    </>
  );

  const renderListsLineContent = () => (
    <div className={`toggle-content ${isHomepage && isMobile ? 'm-t-0-75 p-t-0-75' : ''}`}>
      {
        isMobile
          ? renderLineListMobile(trams, Translate('line.types.tramway'), true, true, true)
          : renderLineListDesktop(trams, Translate('line.types.tramway'), true)
      }

      <div className="border-bottom">
        <Collapsible
          trigger={renderAccordionHeaderBus()}
          open={isMobile}
          triggerTagName="div"
          tabIndex={0}
          transitionTime={300}
        >
          <div className="lines-wrapper display-flex wrap m-b align-content-stretch">
            {priorityBus.map(b => <ScheduleLinePicto line={b} key={b.id} size={30} tagging={tagging} />)}
            {classicBus.map(b => <ScheduleLinePicto line={b} key={b.id} size={30} tagging={tagging} />)}
          </div>
          <div className="lines-wrapper display-flex wrap align-content-stretch">
            {specialBus.map(b => <ScheduleLinePicto line={b} key={b.id} size={30} tagging={tagging} />)}
          </div>
        </Collapsible>
      </div>
      {
        isMobile
          ? busNight.length > 0
            ? renderLineListMobile(busNight, Translate('line.types.busNight'))
            : null
          : busNight.length > 0
            ? renderLineListDesktop(busNight, Translate('line.types.busNight'))
            : null
      }
      {
        isMobile
          ? scodi.length > 0
            ? renderLineListMobile(scodi, Translate('line.types.scodi'))
            : null
          : scodi.length > 0
            ? renderLineListDesktop(scodi, Translate('line.types.scodi'))
            : null
      }
      {
        isMobile
          ? renderLineListMobile(batcub, Translate('line.types.ferry'), true, true)
          : renderLineListDesktop(batcub, Translate('line.types.ferry'), true)
      }
      {
        isMobile
          ? trains.length > 0
            ? renderLineListMobile(trains, Translate('line.types.train'))
            : null
          : trains.length > 0
            ? renderLineListDesktop(trains, Translate('line.types.train'))
            : null
      }
      {
        isMobile
          ? autocars.length > 0
            ? renderLineListMobile(autocars, Translate('line.types.autocar'), false)
            : null
          : autocars.length > 0
            ? renderLineListDesktop(autocars, Translate('line.types.autocar'), false)
            : null
      }
    </div>
  );

  return (
    <div className="tbm-block-toggle link-toggle">
      {
        isMobile && isHomepage ? (
          <Collapsible
            trigger={renderMobileRootAccordionHeader()}
            triggerTagName="div"
            tabIndex={0}
            transitionTime={300}
          >
            {
              renderListsLineContent()
            }
          </Collapsible>
        )
          : (
            <>
              <span>
                {Translate('schedules.search.list')}
                {' '}
                :
              </span>
              {
                renderListsLineContent()
              }
            </>
          )
      }
    </div>
  );
};

ListLinesAccordion.propTypes = {
  trams: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  bus: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  busNight: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  scodi: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  batcub: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  trains: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  autocars: PropTypes.arrayOf(
    PropTypes.shape(shapes.line),
  ).isRequired,
  isMobile: PropTypes.bool.isRequired,
  tagging: PropTypes.string,
};

ListLinesAccordion.defaultProps = {
  tagging: null,
};

const mapStateToProps = state => ({
  trams: state.lines.trams,
  bus: state.lines.bus,
  busNight: state.lines.busNight,
  scodi: state.lines.scodi,
  batcub: state.lines.batcub,
  trains: state.lines.trains,
  autocars: state.lines.autocars,
  isMobile: state.app.isMobile,
});

export default connect(mapStateToProps, null)(ListLinesAccordion);
