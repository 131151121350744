import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import Autosuggest from 'react-autosuggest';
import { TailSpin as Loader } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { SCHEDULES_SEARCH_URL, STOPS_NEARBY } from '../../../config/config';
import { AUTOCOMPLETE_TYPES } from '../../../config/schedule';
import { LINES_TYPES } from '../../../config/line';
import routes from '../../../routes';
import { pushRoute } from '../../../helpers/RouterHelper';
import { ENTITY_PARAM } from '../../../config/map';
import { storeRecentEntities, getRecentEntities } from '../../../helpers/RecentSearchHelper';
import { renderLine, renderPicto } from './ScheduleLinePicto';
import { sortStopsByLine } from '../../../helpers/LineHelper';
import { taggingEventSchedules } from '../../../tagging/schedules/SchedulesTaggingActions';
import { clearStopAreaLines } from '../../../actions/StopActions';
import { setLine } from '../../../actions/LineActions';

const renderLinePicto = (line) => {
  if (line) {
    if (line.picto) {
      return renderPicto(line);
    }
    return renderLine(line, 30);
  }
};

/**
 * Format string formatted as 'Bordeaux;Artigues;Mérignac' to '(Bordeaux) (Artigues) (Mérignac)'.
 * @param cities
 */
const formatCities = cities => (
  cities.split(';').reduce((accumulator, city) => `${accumulator} (${city})`, '')
);

const geolocInput = {
  type: 'geoloc',
  value: '',
};

const loadingInput = {
  type: 'loading',
  value: '',
};

const renderSuggestion = (entity) => {
  const isRecentSearch = typeof entity.isRecentSearch !== 'undefined';

  if (entity.type === 'geoloc') {
    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          <svg width={18} height={18} className="f-blue">
            <use xlinkHref="#icon-gps-location" />
          </svg>
        </div>
        <span className="text-blue">{Translate('nearbyMe.btn_geoloc')}</span>
      </div>
    );
  }

  if (entity.type === 'loading') {
    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          <Loader type="TailSpin" color="#039CD2" height={25} width={25} />
        </div>
      </div>
    );
  }

  if (entity.type === AUTOCOMPLETE_TYPES.LINE) {
    let icon = '';

    switch (entity.mode) {
      case LINES_TYPES.BATCUB:
        icon = 'icon-batcub-circle';
        break;
      case LINES_TYPES.TRAM:
        icon = 'icon-tram-circle';
        break;
      case LINES_TYPES.BUS:
        icon = 'icon-bus-circle';
        break;
      case LINES_TYPES.TRAIN_TER:
        icon = 'icon-sncf-circle';
        break;
      case LINES_TYPES.AUTOCAR:
        icon = 'icon-autocar-circle';
        break;
      default:
        icon = 'icon-transport';
    }

    if (isRecentSearch) {
      icon = 'icon-search';
    }

    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          <svg width={18} height={18} className="f-light">
            <use xlinkHref={`#${icon}`} />
          </svg>
        </div>
        <span>{entity.name}</span>
      </div>
    );
  }

  if (entity.type === AUTOCOMPLETE_TYPES.STOP_POINT) {
    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          <svg width={18} height={18} className="f-light">
            <use xlinkHref={isRecentSearch ? '#icon-search' : '#icon-stoparea'} />
          </svg>
        </div>
        <span>{`${entity.name} ${formatCities(entity.city)}`}</span>
      </div>
    );
  }

  // Last search
  if (entity.type === 'stop_point') {
    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          {
            entity.isRecentSearch
              ? (
                <svg width={18} height={18} className="f-light">
                  <use xlinkHref="#icon-search" />
                </svg>
              ) : renderLinePicto(entity.line)
          }
        </div>
        <div>
          <strong>{entity.name}</strong>
          <svg width={10} height={8} className="f-light display-inline">
            <use xlinkHref="#icon-arrow-right" />
          </svg>
          <span>{'route' in entity ? entity.route.name : entity.routes[0].name}</span>
        </div>
      </div>
    );
  }

  // Favorites
  if ('stopPointId' in entity) {
    return (
      <div className="title-icon display-full">
        <div className="suggestion-icon m-r-0-25">
          {entity.isRecentSearch
            ? (
              <svg width={18} height={18} className="f-light">
                <use xlinkHref="#icon-search" />
              </svg>
            ) : renderLinePicto(entity.line)}
        </div>
        <div className={"suggestion-item"}>
          <span className="text-bold">{`${entity.stopPoint.name} (${entity.stopPoint.city}) `}</span>
          <svg width={10} height={8} className="f-light display-inline">
            <use xlinkHref="#icon-arrow-right" />
          </svg>
          <span>{entity.route.name}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="title-icon display-flex display-full">
      <div className="m-r-0-25">
        {
          <svg width={18} height={18} className="f-light">
            <use xlinkHref="#icon-stoparea" />
          </svg>
          }
      </div>
      <span>{entity.name}</span>
    </div>
  );
};

const AutosuggestLinesAndStops = (props) => {
  const { defaultValue } = props;
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [isTyping, setTyping] = useState(0);
  const [showSuggest, setShowSuggest] = useState(false);
  const [geolocationLoading, setGeolocationLoading] = useState(false);
  const [autocompleteError, setAutocompleteError] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const [input, setInput] = useState(null);
  const favoritesStops = useSelector(state => state.favorites.stops);
  const { lines } = useSelector(state => state.lines);
  const isMobile = useSelector(state => state.app.isMobile);
  const isWebview = useSelector(state => state.app.isWebview);
  const isHomepage = useSelector(state => state.app.isHomepage);
  const [recentSearchStops, setRecentSearchStops] = useState([]);
  const dispatch = useDispatch();

  const referer = isWebview ? 'webview' : 'www';

  useEffect(() => {
    setRecentSearchStops(getRecentEntities(ENTITY_PARAM.STOP));
    setResults(favoritesStops);
  }, []);

  useEffect(() => {
    setSearch(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (geolocationLoading) {
      setResults([loadingInput]);
    }
  }, [geolocationLoading]);

  const clearInput = () => {
    setSearch('');
    setResults(favoritesStops);

    if (input) {
      setTimeout(() => input.focus(), 1);
    }
  };

  const fetchNearbyStops = async (lat, long) => {
    clearInput();
    const nearbyStops = await fetch(`${STOPS_NEARBY}/${long};${lat}?limit=10&referer=${referer}`);
    const nearbyStopsJson = await nearbyStops.json();
    let stops = [];

    nearbyStopsJson.stop_points.forEach((sp) => {
      sp.routes.forEach((route) => {
        const nearbyStop = { ...sp };
        nearbyStop.line = lines.find(line => line.id === route.line_id);
        nearbyStop.route = route;
        nearbyStop.geolocated = true;
        stops.push(nearbyStop);
      });
    });

    stops.sort((a, b) => ((a.line.externalCode > b.line.externalCode) ? 1 : -1));
    stops = sortStopsByLine(stops);

    setGeolocationLoading(false);
    setResults([...stops]);

    return nearbyStopsJson;
  };

  const fetchAutocomplete = async (value) => {
    try {
      const schedulesQuery = await fetch(`${SCHEDULES_SEARCH_URL}/${value}?referer=${referer}`);
      const schedules = await schedulesQuery.json();

      setResults(schedules);
    } catch (e) {
      setAutocompleteError(true);
      // eslint-disable-next-line no-console
      console.error(`Une erreur s'est produite lors de la récupération de l'autocomplete : ${e}`);
    }
  };

  const filterStations = ({ value }) => {
    if (typeof value === 'undefined') {
      return false;
    }

    if (value.trim() !== '') {
      setAutocompleteError(false);

      if (isTyping) {
        clearTimeout(isTyping);
      }

      setTyping(setTimeout(() => fetchAutocomplete(value), 350));
    } else {
      setResults([geolocInput, ...favoritesStops, ...recentSearchStops]);
    }
  };

  const continueSearch = (event, { suggestion }) => {
    const { lang } = props;
    setSearch('');
    event.preventDefault();

    if (suggestion.type !== 'geoloc') {
      storeRecentEntities(suggestion, ENTITY_PARAM.STOP);
      setShowSuggest(false);

      // Favorites
      if ('stopPoint' in suggestion) {
        taggingEventSchedules({ item: 'Favori', timetablesItem: suggestion.stopPoint.name.substr(0, 99) });
        return pushRoute(routes.SCHEDULE.STOP_DETAILS.name, lang, {
          line: suggestion.line.externalCode,
          route: suggestion.routeId,
          stopPoint: suggestion.stopPointId,
        });
      }

      if (suggestion.line) {
        if (suggestion.geolocated) {
          taggingEventSchedules({ item: 'Géolocalisé', timetablesItem: suggestion.name.substr(0, 99) });
        } else {
          taggingEventSchedules({ item: 'Saisie manuelle', timetablesItem: suggestion.name.substr(0, 99) });
        }

        return pushRoute(routes.SCHEDULE.STOP_DETAILS.name, lang, {
          line: suggestion.line.externalCode,
          route: 'route' in suggestion ? suggestion.route.id : suggestion.routes[0].id,
          stopPoint: suggestion.id,
        });
      }

      dispatch(clearStopAreaLines());
      dispatch(setLine(null));

      taggingEventSchedules({ item: 'Saisie manuelle', timetablesItem: suggestion.name.substr(0, 99) });
      pushRoute(routes.SCHEDULE.RESULTS.name, lang, {
        line: suggestion.type === AUTOCOMPLETE_TYPES.LINE ? suggestion.id : undefined,
        stop_area: suggestion.type === AUTOCOMPLETE_TYPES.STOP_POINT ? suggestion.id : undefined,
      });
    } else {
      setGeolocationLoading(true);
      clearInput();

      navigator.geolocation.getCurrentPosition((position) => {
        fetchNearbyStops(position.coords.latitude, position.coords.longitude);
      }, () => {
        setGeolocationLoading(false);
      });

      setShowSuggest(true);
    }
  };

  // eslint-disable-next-line react/prop-types
  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (search.length && isFocus) {
      containerProps.className = `${containerProps.className} react-autosuggest__suggestions-container--open`;
    }

    return (
      <div {...containerProps}>
        {
          search.length && results.length === 0 && isFocus
            ? (
              <ul className="react-autosuggest__suggestions-list">
                <li className="react-autosuggest__suggestion react-autosuggest__suggestion--first">
                  <div className="title-icon display-flex display-full">
                    <span className="autosuggest__message text-light m-l-0-5 m-md-l-0">{Translate('schedules.search.no_result')}</span>
                  </div>
                </li>
              </ul>
            )
            : children
        }
      </div>
    );
  };

  const handleFocus = () => {
    setFocus(true);
    // 2022_HP_RECAST : Do not scroll to form field
    if (!isHomepage) {
      scroller.scrollTo('schedule-scroll-container', {
        duration: 1000,
        smooth: true,
        offset: isMobile ? -20 : -150,
      });
    }
  };

  const renderInputComponent = inputProps => (
    <div onFocus={() => handleFocus()} onBlur={() => setFocus(false)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        className={`${isFocus || search.trim().length > 0 ? 'text-blue' : 'placeholder'} text-small text-medium ${inputProps.value.trim().length > 0 ? ' display-inline-block' : ''}`}
        htmlFor="autosuggest_schedules"
      >
        {`${Translate('schedules.search.placeholder')}`}
      </label>
      <input
        {...inputProps}
        autoComplete="off"
        ref={inputRef => setInput(inputRef)}
      />
      <span
        className="react-autosuggest__clear"
        onClick={() => clearInput()}
        onKeyPress={() => clearInput()}
        role="button"
        tabIndex="0"
      >
        <svg width="12" height="12" className="f-blue">
          <use xlinkHref="#icon-close" />
        </svg>
      </span>
    </div>
  );

  return (
    <div id="schedule-scroll-container" className={search.trim().length > 0 && results.length === 0 ? 'no-result' : ''}>
      <Autosuggest
        suggestions={results}
        onSuggestionsFetchRequested={filterStations}
        onSuggestionsClearRequested={() => setResults([])}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={suggestion => suggestion.name}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={continueSearch}
        shouldRenderSuggestions={() => true}
        alwaysRenderSuggestions={showSuggest}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={{
          value: search,
          placeholder: Translate('schedules.search.placeholder'),
          onChange: e => setSearch(e.target.value),
        }}
      />
      {
        isTyping
          ? <span className="schedule-loading Select-loading" />
          : null
      }
    </div>
  );
};

AutosuggestLinesAndStops.propTypes = {
  defaultValue: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

AutosuggestLinesAndStops.defaultProps = {
  defaultValue: '',
};

const mapStateToProps = state => ({
  lang: state.i18n.locale,
});

export default connect(mapStateToProps, null)(AutosuggestLinesAndStops);
