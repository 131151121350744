import React from 'react';
import { useSelector } from 'react-redux';
import SubtitleIcon from '../../elements/title/SubtitleIcon';
import ListLinesAccordion from './ListLinesAccordion';
import AutosuggestLinesAndStops from '../../elements/schedules/AutosuggestLinesAndStops';
import { SCHEDULES_TAGGING_ACTIONS } from '../../../config/schedule';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';

const SchedulesSearchBlock = () => {
  const isMobile = useSelector(state => state.app);
  const isHomepage = useSelector(state => state.app.isHomepage);

  return (
    <>
      {!isHomepage ? <SubtitleIcon text="schedules.search.title" iconName="icon-search" size={30} /> : null}
      <div className={`tbm-block tbm-block-shadow schedules ${isHomepage ? 'homepage' : 'm-b-2'}`}>
        <div className="filter filter-search filter-full m-b-0">
          <AutosuggestLinesAndStops />
        </div>
        <ListLinesAccordion tagging={SCHEDULES_TAGGING_ACTIONS.ALL_LINES} />
      </div>
    </>
  );
};

export default SchedulesSearchBlock;
